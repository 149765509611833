import React from "react";
import '../styles/mainPage.scss'

import Seo from '../component/Seo'
import Navigation from "../component/navigation/Navigation";
import Footer from "../component/mainPage/footer/Footer";

function policy() {
  return (
    <div className="policyContainer">
      <Seo title="Политика конфиденциальности" />
      <Navigation />
      <div className="policy">
        <p>Политика конфиденциальности</p>

        <p>Уважаемые посетители сайта «Real Element»!</p>

        <p>
          Убедительно просим вас ознакомиться с настоящей Политикой перед тем,
          как предоставлять нам свои персональные данные и приступать к
          использованию сервисов сайта.
        </p>

        <p>1. Общие положения</p>

        <p>
          1.1. Настоящая Политика в отношении обработки и защиты персональных
          данных (далее — «Политика») принята и действует для ООО «Топ Групп» и
          разработана в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ
          «О персональных данных».
        </p>

        <p>
          1.2. Настоящая Политика определяет порядок обработки персональных
          данных и меры по обеспечению защиты персональных данных,
          предоставляемых нам физическими лицами (субъектами персональных
          данных), являющимися незарегистрированными посетителями и (или)
          зарегистрированными пользователями (далее —
          «Пользователи»/«Пользователь» или «Вы») Сайта
          https://realelement.ru/(далее — «сайт Real Element» или «сайт»), при
          использовании полной и мобильной версии сайта, мобильного приложения с
          любого устройства и при коммуникации с нами в любой форме.
        </p>

        <p>
          1.3. Настоящая Политика действует в отношении всей информации, которую
          Компания может получить от Вас во время использования Вами сайта Real
          Element, сервисов, продуктов и услуг.
        </p>

        <p>
          1.4. Используя сервисы сайта https://realelement.ru/и предоставляя нам
          свои персональные данные, Вы даете свое согласие на обработку Ваших
          персональных данных в соответствии с настоящей Политикой.
        </p>

        <p>
          В случае Вашего несогласия с условиями настоящей Политики, Вы должны
          воздержаться от использования сервисов сайта.
        </p>

        <p>
          1.5. Мы исходим из того, что Вы самостоятельно, свободно, своей волей
          и в своём интересе принимаете решение о предоставлении нам своих
          персональных данных и даёте согласие на их обработку.
        </p>

        <p>
          Компания не обладает возможностью оценивать дееспособность
          Пользователей и не проверяет достоверность персональной информации,
          предоставляемой ими.
        </p>

        <p>
          Мы исходим из того, что Вы предоставляете о себе достоверную
          персональную информацию и поддерживаете её в актуальном состоянии.
        </p>

        <p>
          1.6. Целью настоящей Политики является доведение до Вашего сведения
          всей необходимой информации, позволяющей Вам понять, какие
          персональные данные и в каких целях обрабатываются нами, какие методы
          обеспечения их безопасности (конфиденциальности) нами применяются.
        </p>

        <p>
          1.7. Настоящая Политика может быть изменена при изменении действующего
          законодательства Российской Федерации.
        </p>

        <p>
          1.8. Компания оставляет за собой право вносить в положения настоящей
          Политики изменения и дополнения, не противоречащие требованиям
          действующего законодательства Российской Федерации. Новая редакция
          Политики вступает в силу с даты её опубликования на сайте Real
          Element. Действующая редакция Политики всегда будет размещена на сайте
          на этой странице: https://realelement.ru/policy/. Рекомендуем Вам
          время от времени просматривать Политику обработки персональных данных.
          Продолжая пользоваться нашим сайтом, Вы подтверждаете своё согласие с
          внесёнными в Политику изменениями и дополнениями.
        </p>

        <p>
          1.9. Настоящая Политика применяется только к сайту
          https://realelement.ru / . На нашем сайте (https://realelement.ru/)
          могут быть размещены ссылки на другие Интернет-сайты, которые мы не
          контролируем. Мы не несём ответственности за безопасность и
          конфиденциальность любой информации, собираемой сторонними сайтами и
          службами, а также за соблюдение такими третьими лицами требований
          законодательства Российской Федерации.
        </p>

        <p>2. Термины и определения</p>

        <p>
          В настоящей Политике используются термины, имеющие следующее значение:
        </p>

        <p>
          2.1. Персональные данные — любая информация, относящаяся к прямо или
          косвенно определенному или определяемому физическому лицу (субъекту
          персональных данных), то есть информация, которую Пользователь сайта
          предоставляет о себе самостоятельно при регистрации (создании учетной
          записи) на сайте и в процессе использования сервисов сайта, в том
          числе при оформлении заказа товара, предлагаемого к продаже на сайте,
          путем заполнения размещенных на сайте форм.
        </p>

        <p>
          2.2. Обработка персональных данных — любое действие (операция) или
          совокупность действий (операций), совершаемых как с использованием
          средств автоматизации, так и без их использования, с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных.
        </p>

        <p>
          2.3. Распространение персональных данных — действия, направленные на
          раскрытие персональных данных неопределенному кругу лиц.
        </p>

        <p>
          2.4. Конфиденциальность персональных данных — обязательное для
          соблюдения лицом, получившим доступ к персональным данным, требование
          не допускать их распространения без согласия субъекта персональных
          данных или наличия иного законного основания.
        </p>

        <p>
          2.5. Сервисы сайта — функциональные возможности, службы, услуги,
          инструменты, доступные для Пользователей на сайте Real Element.
        </p>

        <p>
          2.6. Пользовательское соглашение — соглашение, регулирующее отношения,
          возникающие между Компанией и Пользователем при использовании сайта
          Real Element и (размещено на сайте Real Element), регламентирующее
          работу сайта Real Element и определяющее порядок использования
          сервисов сайта.
        </p>

        <p>
          2.7. Cookies — небольшой фрагмент данных, отправленный веб-сервером и
          хранимый на компьютере Пользователя, который веб-клиент или
          веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
          попытке открыть страницу соответствующего сайта.
        </p>

        <p>
          2.8. IP-адрес — уникальный сетевой адрес узла в компьютерной сети,
          построенный по протоколу IP.
        </p>

        <p>3. Данные, которые мы обрабатываем</p>

        <p>
          3.1. Мы обрабатываем персональные данные и другую информацию, которую
          Вы можете предоставлять нам при:
        </p>

        <p>Регистрации на сайте Real Element;</p>

        <p>
          Использовании сервисов сайта Real Element и заполнении информационных
          полей на сайте Real Element, в том числе при заполнении контактной
          формы, при подписке на новостную рассылку, регистрации на мероприятия,
          оформлении заказа/приобретении товара или услуг, предлагаемого для
          продажи на сайте Real Element, а также при реализации иных прав;
        </p>

        <p>
          Обращении в службу поддержку сайта Real Element по любым каналам
          связи;
        </p>

        <p>Регистрации на наши мероприятия;</p>

        <p>
          Прохождении опросов и участии в исследованиях, организованных
          Компанией;
        </p>

        <p>
          Обращении по вопросам заключения договора коммерческой концессии
          (франчайзинга) и т.д.
        </p>

        <p>
          3.1.1. Компания осуществляет обработку следующих Ваших персональных
          данных, которые Вы самостоятельно предоставляете о себе на сайте Real
          Element в процессе использования сайта, в том числе при оформлении
          различных форм оформления заявок:
        </p>

        <p>Фамилия, имя, отчество</p>

        <p>Контактный номер телефона</p>

        <p>Адрес электронной почты (e-mail)</p>

        <p>Адрес</p>

        <p>Любые другие персональные данные, которые Вы сообщите Компании.</p>

        <p>
          Часть персональных данных Пользователя является обязательной для
          предоставления Компании в целях заключения (исполнения) договоров,
          заключаемых между Пользователем и Компанией, другая часть —
          необязательна и может предоставляться Пользователем по его желанию и
          усмотрению в целях повышения качества оказываемых услуг и для иных
          установленных соответствующими соглашениями (договорами) целей.
        </p>

        <p>
          Обязательная персональная информация о Пользователе, которая
          необходима Компании для предоставления ему услуг, исполнения условий
          договоров, обозначена на сайте специальным образом (звездочкой). Иные
          сведения предоставляются Пользователем по его усмотрению.
        </p>

        <p>
          3.1.2. Компания также осуществляет обработку технической и иной
          информации (в том числе информации, сохраненной в файлах куки
          (Cookies)), которая автоматически передается техническим устройством,
          используемым Пользователем, программному обеспечению Компании при
          обращении Пользователя к сайту Real Element, (далее — «технические
          данные») в том числе:
        </p>

        <p>Данные IP-адрес Пользователя;</p>

        <p>данные файлов Cookies;</p>

        <p>сведения о местоположении Пользователя;</p>

        <p>
          сведения о браузере Пользователя (ином программном обеспечении,
          посредством (с помощью) которого Пользователь получает (осуществляет)
          доступ на сайт Real Element);
        </p>

        <p>
          технические характеристики оборудования (устройства) и программного
          обеспечения, используемого Пользователем;
        </p>

        <p>
          дата, время и количество посещений сайта Real Element Пользователем;
        </p>

        <p>
          адрес сайта, с которого Пользователь осуществил переход на сайт Real
          Element;
        </p>

        <p>адреса запрашиваемых страниц сайта Real Element;</p>

        <p>
          иная подобная информация, которая передается в рамках
          стандартизированных протоколов передачи информации посредством сети
          Интернет.
        </p>

        <p>
          3.2. Компания не осуществляет обработку биометрических персональных
          данных и специальных категорий персональных данных Пользователей.
        </p>

        <p>4. Правовые основания обработки персональных данных</p>

        <p>Мы обрабатываем Ваши персональные данные в следующих случаях:</p>

        <p>Обработка осуществляется с Вашего согласия;</p>

        <p>
          Обработка необходима для заключения, исполнения, изменения или
          прекращения Пользовательского соглашения и договора купли-продажи
          товара,услуги;
        </p>

        <p>
          Обработка необходима для осуществления прав и законных интересов
          Компании или третьих лиц, если при этом не нарушаются права и свободы
          субъекта персональных данных;
        </p>

        <p>
          Обработка необходима для выполнения функций и обязанностей,
          возложенных на Компанию законодательством Российской Федерации;
        </p>

        <p>
          Обработка осуществляется в связи с участием в судопроизводстве или для
          исполнения акта, подлежащего исполнению в соответствии с
          законодательством об исполнительном производстве.
        </p>

        <p>5. Цели обработки персональных данных</p>

        <p>
          5.1. Мы обрабатываем только те Ваши персональные данные, которые
          необходимы для предоставления Вам сервисов сайта и исполнения
          соглашений и договоров, заключаемых с Вами.
        </p>

        <p>
          5.2. Мы осуществляем обработку персональных данных, предоставляемых
          Вами, исключительно для тех целей, для которых они предоставлены, в
          том числе для:
        </p>

        <p>Пользоваться сервисами сайта Real Element;</p>

        <p>
          Оформления заказа и заключения, исполнения, изменения и прекращения
          Пользовательского соглашения, договора купли-продажи товара
          дистанционным способом, заключаемого на условиях, указанных в оферте,
          в том числе для уведомления Вас о состоянии Вашего заказа, обработки и
          получении платежей, организации доставки заказа и т.п.;
        </p>

        <p>
          Информирования Вас (с Вашего согласия) о продуктах (товарах), в том
          числе о новых товарах и ценах, услугах, мероприятиях, рекламных
          акциях, скидках и специальных предложениях, действующих на сайте, о
          наших партнерах, реализации программ лояльности и бонусных программ,
          размещенных на сайте Real Element;
        </p>

        <p>
          Установления обратной связи с Вами, в том числе направления вам
          уведомлений, запросов, касающихся использования сайта Real Element,
          оказания услуг, обработки Ваших запросов и заявок, предоставление Вам
          клиентской и технической поддержки, связанной с использованием сайта
          Real Element;
        </p>

        <p>
          Организации Вашего участия в мероприятиях и опросах, организованных
          нами и нашими партнерами;
        </p>

        <p>
          Предупреждения и пресечения нарушений Пользовательского соглашения и
          иных незаконных или несанкционированных действий Пользователей или
          третьих лиц;
        </p>

        <p>
          Соблюдение требований и выполнение процедур, предусмотренных
          законодательством Российской Федерации, в том числе для целей
          бухгалтерского учёта и налоговой отчётности, реагирования на запросы
          государственных органов;
        </p>

        <p>
          Анализа данных с целью улучшения пользовательского опыта и повышения
          качества сервисов сайта Real Element;
        </p>

        <p>Ведения, управления и актуализации клиентской базы Компании;</p>

        <p>
          Анализа данных с целью улучшения пользовательского опыта и повышения
          качества сервисов сайта Real Element.
        </p>

        <p>5.3. Мы обрабатываем технические данные для:</p>

        <p>
          Обеспечения функционирования и безопасности сайта Real Element, в том
          числе выявления технических проблем;
        </p>

        <p>
          Предупреждения и пресечения нарушений Пользовательского соглашения и
          иных незаконных или несанкционированных действий Пользователей или
          третьих лиц;
        </p>

        <p>
          Соблюдение требований и выполнение процедур, предусмотренных
          законодательством Российской Федерации;
        </p>

        <p>
          Анализа данных с целью повышения качества сервисов сайта Real Element
          и маркетинговых мероприятий Компании.
        </p>

        <p>
          6. Условия обработки персональных данных Пользователей и их передачи
          третьим лицам. Трансграничная передача персональных данных
        </p>

        <p>
          6.1. Обработка персональных данных Пользователя осуществляется только
          с согласия Пользователя на обработку его персональных данных.
        </p>

        <p>
          6.2. Обработка Персональных данных Пользователя в целях, указанных в
          настоящей Политике, осуществляется на условиях добровольного,
          конкретного, информированного и однозначного согласия Пользователя, на
          условиях, определенных настоящей Политикой.
        </p>

        <p>
          6.3. Обработка персональных данных Пользователя осуществляется без
          ограничения срока любым законным способом, в том числе в
          информационных системах персональных данных с использованием средств
          автоматизации или без использования таких средств. Обработка
          персональных данных Пользователей осуществляется в соответствии с
          Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных».
        </p>

        <p>
          6.4. Доступ к Вашим персональным данным имеют только уполномоченные
          сотрудники Компании.
        </p>

        <p>
          6.5. Мы можем передать Ваши персональные данные только государственным
          органам в соответствии с требованиями законодательства Российской
          Федерации, новым владельцам Компании, в случае какого-либо судебного
          разбирательства или возможного судебного разбирательства, а также для
          установления, осуществления и защиты наших законных прав (включая
          предоставление информации третьим лицам с целью предотвращения
          возможности мошенничества или сокращения финансовых рисков в
          соответствии с действующим законодательством Российской Федерации). В
          этом случае мы сообщим Вам о факте передачи Ваших персональных данных
          и о лице, которому была передана эта информация.
        </p>

        <p>
          6.6. В отношении персональных данных Пользователя сохраняется
          конфиденциальность, кроме случаев добровольного предоставления
          Пользователем информации о себе для общего доступа неограниченному
          кругу лиц. При использовании отдельных сервисов сайта Real Element
          Пользователь соглашается с тем, что определенная часть его
          персональной информации становится общедоступной.
        </p>

        <p>
          6.7. При утрате или разглашении персональных данных Компания
          информирует Пользователя об утрате или разглашении персональных
          данных.
        </p>

        <p>
          6.8. Для достижения целей обработки персональных данных мы можем
          передавать Ваши персональные данные и поручать их обработку третьим
          лицам, в том числе на территории иностранных государств. В этом случае
          мы убедимся, что государство, на территорию которого планируются
          передаваться Ваши персональные данные, обеспечивает адекватную защиту
          персональных данных.
        </p>

        <p>
          6.9. Трансграничная передача персональных данных на территорию
          иностранных государств, не обеспечивающих адекватной защиты
          персональных данных, осуществляется только с Вашего письменного
          согласия, либо для исполнения договора, по которому Вы являетесь
          стороной, а также в иных случаях, предусмотренных законодательством
          Российской Федерации о персональных данных.
        </p>

        <p>7. Безопасность персональных данных</p>

        <p>
          7.1. Мы принимаем все необходимые меры для того, чтобы гарантировать
          конфиденциальность и безопасность Ваших персональных данных.
        </p>

        <p>
          7.2. Для обеспечения безопасности Ваших персональных данных при их
          обработке мы принимаем необходимые и достаточные правовые,
          организационные и технические меры для защиты персональных данных от
          неправомерного или случайного доступа к ним, их уничтожения,
          изменения, блокирования, копирования, предоставления, распространения,
          а также от иных неправомерных действий в отношении персональных
          данных.
        </p>

        <p>
          7.3. В целях обеспечения адекватной защиты Ваших персональных данных
          мы проводим оценку вреда, который может быть причинен в случае
          нарушения безопасности Ваших персональных данных, а также определяем
          актуальные угрозы безопасности Ваших персональных данных при их
          обработке в информационных системах персональных данных.
        </p>

        <p>8. Ваши права</p>

        <p>
          8.1. Обеспечение защиты Ваших прав и свобод в сфере персональных
          данных — важное условие работы Компании.
        </p>

        <p>
          8.2. Вы вправе в любое время отозвать свое согласие на обработку
          персональных данных путем направления электронного сообщения на адрес
          электронной почты: manager@megumi.ru. В теме письма необходимо указать
          «Отзыв согласия на обработку персональных данных», в самом письме
          необходимо указать адрес электронной почты, с которой поводилась
          регистрация (в случае, если он не совпадает с электронным адресом
          отправителя), а также перечень отзываемых Вами Персональных данных.
        </p>

        <p>
          8.3. При Вашем желании и согласии Вы можете получать на личную почту
          периодические рассылки от Компании, информирующие Вас о проходящих
          акциях, знакомящих Вас с новостями Компании и её партнеров, новыми
          продуктами, ценами на них и т.п. Вы добровольно выбираете, получать
          Вам рассылку или нет
        </p>

        <p>
          8.4. Чтобы обеспечить защиту Ваших прав и свобод, по Вашей просьбе мы:
        </p>

        <p>
          Подтвердим, обрабатываем ли мы Ваши персональные данные и предоставим
          Вам возможность с ними ознакомиться в течение 30 календарных дней с
          даты получения Вашего запроса;
        </p>

        <p>
          Сообщим Вам об источнике получения и составе Ваших персональных
          данных, которые мы обрабатываем;
        </p>

        <p>
          Сообщим Вам о правовых основаниях, целях, сроках и способах обработки
          Ваших персональных данных;
        </p>

        <p>
          Внесем необходимые изменения в Ваши персональные данные, если Вы
          подтвердите, что они неполные, неточные или неактуальные, в течение 7
          рабочих дней с даты получения подтверждения, и уведомим Вас о
          внесенных изменениях;
        </p>

        <p>
          Сообщим Вам об осуществленной или о предполагаемой трансграничной
          передаче Ваших персональных данных;
        </p>

        <p>
          Сообщим Вам наименование и место нахождения организаций, которые имеют
          доступ к Вашим персональным данным и которым могут быть раскрыты Ваши
          персональные данные с Вашего согласия;
        </p>

        <p>
          Сообщим Вам наименование или фамилию, имя, отчество и адрес лиц,
          которым с Вашего согласия может быть поручена обработка Ваших
          персональных данных;
        </p>

        <p>
          Уведомим Вас о порядке осуществления Ваших прав при обработке нами
          Ваших персональных данных;
        </p>

        <p>Исключим Вас из рассылки наших новостных материалов;</p>

        <p>
          Прекратим обработку Ваших персональных данных в течение 30 календарных
          дней с даты получения отзыва согласия, если для обработки персональных
          данных не будет иных правовых оснований, предусмотренных
          законодательством Российской Федерации;
        </p>

        <p>
          Прекратим обработку Ваших персональных данных, если будет
          подтверждено, что мы их обрабатываем неправомерно, и уведомим Вас о
          предпринятых мерах;
        </p>

        <p>
          Уничтожим Ваши персональные данные, если будет подтверждено, что они
          незаконно получены или не соответствуют заявленным целям обработки, в
          течение 7 рабочих дней с даты получения соответствующего
          подтверждения, и уведомим Вас о предпринятых мерах;
        </p>

        <p>
          Ответим на Ваши вопросы, касающиеся Ваших персональных данных, которые
          мы обрабатываем.
        </p>

        <p>9. Прекращение обработки персональных данных</p>

        <p>9.1. Мы прекращаем обработку Ваших персональных данных:</p>

        <p>
          При наступлении условий прекращения обработки персональных данных или
          по истечении установленных сроков;
        </p>

        <p>
          По достижении целей их обработки либо в случае утраты необходимости в
          достижении этих целей;
        </p>

        <p>
          По Вашему требованию, если обрабатываемые персональные данные являются
          незаконно полученными или не являются необходимыми для заявленной цели
          обработки;
        </p>

        <p>
          В случае выявления неправомерной обработки персональных данных, если
          обеспечить правомерность обработки невозможно;
        </p>

        <p>
          По истечении срока действия Вашего согласия на обработку персональных
          данных или в случае отзыва Вами такого согласия, если для обработки
          персональных данных не будет иных правовых оснований, предусмотренных
          законодательством Российской Федерации;
        </p>

        <p>В случае ликвидации Компании.</p>

        <p>10. Cookies</p>

        <p>
          10.1. Мы используем файлы «Сookies» (куки). Файлы «Сookies» — это
          небольшие текстовые файлы, размещаемые на жёстких дисках Ваших
          устройств во время использования различных сайтов, предназначенные для
          содействия в настройке пользовательского интерфейса в соответствии с
          Вашими предпочтениями.
        </p>

        <p>
          10.2. Большинство браузеров позволяют отказаться от получения файлов
          «cookies» и удалить их с жёсткого диска устройства. По своему желанию
          Вы вправе в любой момент отключить Cookies. Однако отключение Cookies
          может повлечь невозможность доступа к некоторых сервисам и
          возможностям нашего сайта.
        </p>

        <p>11. Реквизиты Компании:</p>

        <p>
          Полное официальное наименование предприятия: Общество с ограниченной
          ответственностью "Топ групп"
        </p>

        <p>Сокращенное наименование предприятия: ООО «Топ групп»</p>

        <p>Директор: Клисаков Дмитрий Александрович</p>

        <p>ИНН 2465143647</p>

        <p>ОГРН 1162468070160</p>

        <p>Юридический адрес: 660133 г.Красноярск, ул. С.Лазо , д.12А-10</p>

        <p>Телефон: 8 (913) 533-18-98</p>

        <p>Номер расчетного счета: 40702810206500005968</p>

        <p>
          Наименование учреждения банка: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"
          Корреспондентский счет банка: 30101810845250000999
        </p>

        <p>БИК 0044525999</p>

        <p>e-mail: topgroups@yandex.ru</p>
      </div>
      <div className="footerContainer">
        <Footer />
      </div>
    </div>
  );
}

export default policy;
